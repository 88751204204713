export const versions = [
    {
        version: '0.9',
        date: '18/03/2024',
        author: 'Jonatan',
        description: ['Adicionado Bolinha no card de financeiro, que representa a quantidade de pendências que o sócio tem', 'Ao abriu modal na competição trazer data de inicio e fim da competição, ao selecionar a competição se data atual estiver entre a data de inicio e a data de fim, preenche o campo data da competição com a data atual', "Adicionado a opção de excluir arma no módulo acervo se arma não tiver pendências."],
    },
    {
        version: '1.0',
        date: '24/03/2024',
        author: 'Jonatan',
        description: ["Ajustando erro que não preenchia de forma automatica o SIGMA na arma na competiçao", "Adicionado novos campos no acervo pessoa 'Dados da compra'", "Ajustado erro que selecionada todas as armas pelo nome, adicionado condição de nome e número de série"],
    },
    {
        version: '1.1',
        date: '28/03/2024',
        author: 'Jonatan',
        description: ["Erro: Não salvava no treinamento e competição ao ser armaPropria == 0, corrigido.", "Salvava pessoaArmaID mesmo sendo armaPropria == 0", "Ajustado reset de campos ao criar nova (competição ou treinamento)"],
    },
    {
        version: '1.2',
        date: '09/04/2024',
        author: 'Jonatan',
        description: ["Possibilidade de adicionar anexos no perfil", "ajustado o estilo ao mostrar imagens para limitar a quantidade de caracteres para não quebrar layout"],
    },
    {
        version: '1.3',
        date: '10/05/2024',
        author: 'Jonatan, Roberto',
        description: ["Implementação da página de log", "Tema dark adicionado"],
    },
    {
        version: '1.4',
        date: '14/05/2024',
        author: 'Jonatan, Roberto',
        description: ["Adicionado card para nos avaliar no google", "No cadastro da arma, o campo `NUMERO REGISTRO` agora é obrigatório", "Adicionado o total de pessoas que acessou o sistema no dia, na tabela de log"],
    },
    {
        version: '1.5',
        date: '16/05/2024',
        author: 'Jonatan, Roberto',
        description: ["Adicionado paginação e filtrro na tela de histórico"],
    },
    {
        version: '1.6',
        date: '16/05/2024',
        author: 'Jonatan, Roberto',
        description: ["Adicionado mensagem para socios, referente ao declero 11.615 no módulo mensagens"],
    },
    {
        version: '1.7',
        date: '17/05/2024',
        author: 'Jonatan, Roberto',
        description: ["Adicionado Resumo no módulo histórico", "Adicionado filtros no módulo histórico"],
    },
    {
        version: '1.8',
        date: '24/05/2024',
        author: 'Jonatan, Roberto',
        description: ["Ajuste erro de log, de gravar o id do log no lugar de lastid()", "Ajustado erro ao exebir fotos no historico/detalhado"],
    },
    {
        version: '1.9',
        date: '27/05/2024',
        author: 'Jonatan, Roberto',
        description: ["Adicionado getDataContext, agora os getData estão armazenando em estado global para melhor experiencia do usuario"],
    },
    {
        version: '2.0',
        date: '10/06/2024',
        author: 'Jonatan, Roberto',
        description: ["Adicionado verificação ao gravar treinamento, verificando a data, armaID, horaInicio. Os mesmos não podem ser duplicados"],
    },
    {
        version: '2.1',
        date: '12/06/2024',
        author: 'Jonatan, Roberto',
        description: ["Histórico só traz registros anteriores a data atual", "Filtro do historico so permite datas anteriores ou igual a data atual", 'Traz mensagem se já tiver lançamentos de treinamento para a data selecionada e arma selecionada', 'tag nos card do histórico dizendo de onde veio a habitualidade (aplicativo, habitualidade, atendimento)',],
    },
    {
        version: '2.2',
        date: '02/07/2024',
        author: 'Jonatan, Roberto',
        description: ["Ajuste de envio de imagem do iphone(HEIC, HEIF)"],
    },
    {
        version: '2.3',
        date: '05/07/2024',
        author: 'Jonatan, Roberto',
        description: ["Adicionado nossa imagem nas mensagens, e adicionado nosso alerta no icone de mensagem"],
    },
    {
        version: '2.4',
        date: '12/07/2024',
        author: 'Jonatan',
        description: ["Na habitualidade(treinamento, competição), agora os campos (folha e livro) são obrigatórios."],
    },
    {
        version: '2.5',
        date: '12/07/2024',
        author: 'Jonatan',
        description: ["Ajustado select da arma no módulo habitualidade(treinamneto/competição)"],
    },
    {
        version: '2.6',
        date: '02/09/2024',
        author: 'Roberto',
        description: ["Correção na seleção de arma ao inserir arma ao acervo."],
    },
    {
        version: '2.7',
        date: '03/09/2024',
        author: 'Roberto',
        description: ["Correção na seleção de arma nas habitualidades."],
    },
    {
        version: '2.81',
        date: '10/10/2024',
        author: 'Roberto',
        description: ["Inserção de um badge informando ao sócio se a presença foi confirmada ou recusada pelo clube."],
    },
]